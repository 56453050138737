<template>
  <div class="ExportingCountryTrade">
    <div class="board-title">总贸易额&出口国</div>
    <div ref="DoubleRingRef" class="ExportingCountryTrade-chart"></div>
    <div class="ExportingCountryTrade-bottom">
      <template v-for="(item, index) in totalList">
        <div :key="index" class="ExportingCountryTrade-bottom-item">
          <div class="ExportingCountryTrade-bottom-item-top">
            <div class="icon"></div>
            <div class="name">{{ item.label }}</div>
          </div>
          <div class="ExportingCountryTrade-bottom-item-bottom">
            <p>
              <span>USD</span>
              <span class="value">{{ formatNum((item.USD / 10000), 3) }}</span>
              <span>万</span>
            </p>
            <p>
              <span>JPY</span>
              <span class="value">{{ formatNum((item.JPY / 10000), 3) }}</span>
              <span>万</span>
            </p>
          </div>
          <!-- <div class="icon"></div>
          <el-statistic
            :title="item.label"
            group-separator=","
            :precision="2"
            :value="item.value"
            suffix="万元"
          ></el-statistic> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatNum } from '@/utils';
import $echartsConfig from '@/utils/echartsConfig';

export default {
    name: 'ExportingCountryTrade',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formatNum,
        echartsView: null,
        pieOption: null,
        colors: [ '#376FE3', '#5026E4', '#8D25E4', '#DE343E', '#DF7221', '#E1B31F'],
        overviewData: {
          9610: {
            USD: 0,
            JPY: 0
          },
          9710: {
            USD: 0,
            JPY: 0
          },
          9810:  {
            USD: 0,
            JPY: 0
          },
          1210:  {
            USD: 0,
            JPY: 0
          },
        }
      }
    },
    computed: {
      totalList() {
        const totalList = [];
        Object.entries(this.overviewData).reduce((prev, curr) => {
          const [ label, { USD, JPY } ] = curr;
          totalList.push({ label, USD, JPY });
        }, {});
        return totalList;
      }
    },
    mounted () {
      this.initChart();
      this.geRatiotData();
      this.getAmountData();
    },
    methods: {
      initChart() {
        this.echartsView = $echartsConfig.echarts.init(this.$refs.DoubleRingRef);
        this.initChartOption();
      },
      initChartOption() {
        this.pieOption = {
          title: '',
          color: [ '#2E5EC1', '#4420C2', '#771FC2', '#BC2C34', '#BD611C', '#BF981A' ],
          tooltip: {
            trigger: 'item'
          },
          grid: {
            ...$echartsConfig.grid,
            right: 30,
          },
          legend: {
            ...$echartsConfig.legend,
            orient: 'vertical',
            top: 'middle',
            right: 24,
            // formatter: (name) => {
            //   let total = 0
            //   let tarValue = 0;
            //   const { seriesData } = this.optionsData; 
            //   for (let i = 0; i < seriesData.length; i++) {
            //     total += seriesData[i].value
            //     if (seriesData[i].name == name) {
            //       tarValue = seriesData[i].value
            //     }
            //   }
            //   // let v = tarValue + 'G';
            //   //计算出百分比
            //   let p = ((tarValue / total) * 100).toFixed(2) + '%'  
            //   return `${name}  ${p}`
            //   //name是名称，v是数值
            // }
          },
          series: [
            {
              name: '业务类型申报量',
              type: 'pie',
              radius: ['30%', '45%'],
              center: ['45%', '50%'],
              label: {
                show: false,
              },
              labelLine: {
                show: false
              },
              emphasis: {
                disabled: true,
              },
              data: [],
            },
            {
              name: '业务类型申报量',
              type: 'pie',
              radius: ['45%', '65%'],
              center: ['45%', '50%'],
              label: {
                show: true,
                fontSize: 14,
                color: 'rgba(255, 255, 255, 1)',
                minMargin: 15,
                edgeDistance: 20,
                lineHeight: 15,
                formatter(param) {
                  return `${param.name}\n ${param.percent}%`;
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              data: [],
            },
          ],
        };
        this.echartsView.setOption(this.pieOption);
      },
      // 总贸易国占比
      async geRatiotData() {
        const resRatio = await this.$request.get('/data/countryBusinessData/ratio', { params: this.queryDate });
        if(resRatio.data && resRatio.data.length) {
          const seriesData = resRatio.data.map((item, index) => ({
            name: item.countryName,
            value: item.num,
            itemStyle: {
              color: this.colors[index]
            }
          }));
          this.updateChartOption(seriesData);
        }
      },
      // 总贸易额
      async getAmountData() {
        const resAmount = await this.$request.get('/data/businessTypeData/tradeAmount', { params: { ...this.queryDate}});
        if(resAmount.data) {
          Object.keys(resAmount.data)
          .filter(tradeMode => ['9610', '9710', '9810', '1210'].includes(tradeMode))
          .forEach(tradeMode => {
            this.overviewData[tradeMode] = resAmount.data[tradeMode].reduce((prev, cur) => {
              const { currency, totalPrice } = cur;
              if(currency === '美元') {
                prev.USD += totalPrice;
              } else if(currency === '日本元') {
                prev.JPY += totalPrice;
              }
              return prev;
            }, {
              USD: 0,
              JPY: 0
            })
          })
          // Object.values(resAmount.data).forEach(itemList => {
          //   itemList.forEach(item => {
          //     const { tradeMode, totalPrice } = item;
          //     this.overviewData[tradeMode].USD += ( totalPrice / 10000);
          //   })
          // })
        }
        // const resAmountJPY = await this.$request.get('/data/businessTypeData/tradeAmount', { params: { ...this.queryDate, currency: '日本元'}});
        // if(resAmountJPY.data) {
        //   Object.values(resAmountJPY.data).forEach(itemList => {
        //     itemList.forEach(item => {
        //       const { tradeMode, totalPrice } = item;
        //       this.overviewData[tradeMode].JPY += totalPrice;
        //     })
        //   })
        // }
      },
      updateChartOption(seriesData){
        let option = {
          series: [
            {
              data: seriesData,
            },
            {
              data: seriesData.map((item, index) => ({
                ...item,
                itemStyle: {
                  color: this.colors[index]
                }
              })),
            }
          ]
        };
        this.echartsView.setOption(option, false);
      }
    },
  }
</script>

<style lang="scss" scoped>
.ExportingCountryTrade{
  width: 100%;
  height: 100%;
  .board-title{
    background-position: 33% 47%;
  }
  &-chart{
    height: 280px;
  }
  &-bottom{
    padding-bottom: 20px;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-item{
      width: 236px;
      padding: 10px 24px;
      background-color: rgba(24,122,205,0.2);
      box-sizing: border-box;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;
      }
      &-top{
        display: flex;
        .icon{
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/dataScreen/icon_money.svg') center center no-repeat;
        }
        .name{
          margin-left: 15px;
          color: #34DBFC;
        }
      }
      &-bottom{
        display: flex;
        flex-direction: column;
        p{
          display: flex;
          align-items: center;
          span{
            font-size: 12px;
            color: #FFFFFF;
            &.value{
              margin: 0 7px 0 15px;
              color: #E1B31F;
              font-size: 24px;
            }
          }
        }
      }
      ::v-deep(.el-statistic){
        text-align: left;
        .head{
          padding-left: 15px;
          .title{
            color: #34DBFC;
          }
        }
        .con{
          vertical-align: bottom;
          .number{
            padding-left: 0;
            font-size: 24px;
            font-weight: 700;
            font-family: DINAlternate-Bold, DINAlternate;
            color: #fff;
            text-align: left;
          }
          .suffix{
            font-size: 12px;
            color: #fff;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>