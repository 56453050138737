<template>
  <div class="Overview">
    <template v-for="(value, key, index) in businessOptions">
      <div :key="index" class="Overview-item">
        <el-statistic
          :title="key"
          group-separator=","
          :precision="0"
          :value="value"
        ></el-statistic>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Overview',
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        businessOptions: {
          '9610': 0,
          // '9710清单': 0,
          '9710': 0,
          '9810': 0,
          '1210': 0
        }
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    mounted () {
      this.getDeclareData();
    },
    methods: {
      async getDeclareData() {
        const res = await this.$request.get('/data/businessTypeData/declareTotalNum', { params: this.queryDate});
        if(res.data && res.data.length) {
          res.data.forEach(item => {
            const { tradeMode, num } = item;
            let options = {
              name: tradeMode,
              value: num
            };
            const isHas = Object.keys(this.businessOptions).findIndex(tradeMode => tradeMode === options.name) !== -1;
            if(isHas) {
              this.businessOptions[options.name] =  options.value; 
            }
          });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.Overview{
  display: flex;
  justify-content: space-around;
  padding: 20px 10px;
  box-sizing: border-box;
  &-item {
    width: 170px;
    padding: 20px 20px;
    background-color: rgba(24, 122, 205, 0.2);
    box-sizing: border-box;
    ::v-deep(.el-statistic){
      text-align: left;
      .head{
        margin-bottom: 10px;
        text-align: center;
        .title{
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .con{
        .number{
          font-size: 24px;
          font-weight: 700;
          font-family: DINAlternate-Bold, DINAlternate;
          color: #fff;
        }
      }
    }
  }
}
</style>