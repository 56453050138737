<template>
  <div id="map" class="Map-container">

  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
    name: 'Map',
    props: {
      isPulseLink: {
        type: Boolean,
        default: false
      },
      points: {
        type: Object,
        required: true 
      },
      lines: {
        type: Object,
        required: true 
      },
      zoom: {
        type: Number,
        default: 4.5
      },
      rotation: {
        type: Number,
        default: 0
      },
      centerPointSize: {
        type: Array,
        default: () => [300000, 300000]
      }
    },
    data() {
      return {
        AMap: null,
        Loca: null,
        map: null,
        layer: {
          label: null,
          link: null,
          scatter1: null,
          scatter2: null,
          scatter3: null,
        },
        lineGeoMapData: null,
        scatterGeoMapData: null
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.initMap();
      })
    },
    methods: {
      initMap() {
        window._AMapSecurityConfig = {
          securityJsCode: 'f8219cd8a9397e5ffb04e11058f4dd07'
        }
        AMapLoader.load({
            "key": "bc7befd20138846d15039f98d1f6ce0e",             
            "version": "2.0",  
            "plugins": [],
            Loca:{
              version: '2.0'
            },
        }).then((AMap)=>{
            this.AMap = AMap;
          // eslint-disable-next-line no-undef
            this.Loca = Loca;
            this.loaderMap();

        }).catch(e => {
            console.log(e);
        })
      },
      loaderMap(){
        this.map = new this.AMap.Map('map',  {
          zoom: this.zoom,
          pitch: 40,
          rotation: this.rotation,
          showLabel: false,
          viewMode: '3D',
          resizeEnable: true,
          center: [ 120.163381, 36.233834 ],
          mapStyle: 'amap://styles/bbf8c99c8020b8af3becf98a65a8a056',
        });
        this.loca = new this.Loca.Container({
          map: this.map,
        });
        this.loaderLabelLayer();
        this.loaderLinkLayer();
        this.loaderScatterLayer();
        this.loaderCenterPoint();
        this.requestGeoJson();
      },
      // 文字图层
      loaderLabelLayer() {
        this.layer.label = new this.AMap.LabelsLayer({
          rejectMapMask: true,
          collision: true,
          animation: true,
        });
        this.map.add(this.layer.label);
      },
      // 飞线图层
      loaderLinkLayer() {
        if(this.isPulseLink) {// 弧线
          this.layer.link = new this.Loca.PulseLinkLayer({
            zIndex: 10,
            opacity: 1,
            visible: true,
            zooms: [2, 22],
            depth: true,
          });
        } else {
          this.layer.link = new this.Loca.LinkLayer({
            zIndex: 10,
            opacity: 1,
            visible: true,
            zooms: [2, 22],
          });
        }
      },
      // 散点图层
      loaderScatterLayer() {
        this.layer.scatter1 = new this.Loca.ScatterLayer({
            zIndex: 10,
            opacity: 1,
            visible: true,
            zooms: [2, 22],
        });
        this.layer.scatter2 = new this.Loca.ScatterLayer({
            zIndex: 10,
            opacity: 0.8,
            visible: true,
            zooms: [2, 22],
        });
        this.layer.scatter3 = new this.Loca.ScatterLayer({
            zIndex: 10,
            opacity: 0.8,
            visible: true,
            zooms: [2, 22],
        });
      },
      // 加载中心点
      loaderCenterPoint() {
        const centerPoint = new this.Loca.GeoJSONSource({
          data: {
              'type': 'FeatureCollection',
              'features': [
                  {
                      'type': 'Feature',
                      'geometry': {
                          'type': 'Point',
                          'coordinates': [120.163381, 36.233834],
                      },
                  },
              ],
          },
        });
        this.layer.scatter3.setSource(centerPoint);
        this.layer.scatter3.setStyle({
            size: this.centerPointSize,
            unit: 'meter',
            texture: 'https://a.amap.com/Loca/static/static/center-point.png',
        });
        this.loca.add(this.layer.scatter3);
      },
      requestGeoJson() {
        let filterGeoJSON = (json, type) => {
          const newJSON = {
              type: 'FeatureCollection',
              features: [...json.features.filter((item) => item.properties.type === type)],
          };
          return new this.Loca.GeoJSONSource({
              data: newJSON,
          });
        };
        const setLabelsLayer = (data) => {
            this.layer.label.clear();
            data.features.forEach((item) => {
                var labelsMarker = new this.AMap.LabelMarker({
                    name: item.properties.flagName,
                    position: item.geometry.coordinates,
                    zooms: [2, 22],
                    opacity: 1,
                    zIndex: 10,
                    text: {
                        content: item.properties.country,
                        direction: 'bottom',
                        offset: [0, -5],
                        style: {
                            fontSize: 13,
                            fontWeight: 'normal',
                            fillColor: '#fff',
                        },
                    },
                });
                this.layer.label.add(labelsMarker);
            });
            this.layer.label.add(
                new this.AMap.LabelMarker({
                    name: 'china',
                    position: [120.163381,36.233834],
                    zooms: [2, 22],
                    opacity: 1,
                    zIndex: 10,
                    rank: 100,
                    text: {
                        content: '青岛',
                        direction: 'bottom',
                        offset: [0, -5],
                        style: {
                            fontSize: 13,
                            fontWeight: 'normal',
                            fillColor: '#fff',
                        },
                    },
                }),
            );
        };
        // fetch('https://a.amap.com/Loca/static/static/diplomacy-point.json')
        //     .then((res) => res.json())
        //     .then((data) => {
                // this.scatterGeoMapData = data;
                setLabelsLayer(this.points);
                var source1 = filterGeoJSON(this.points, 0);
                var source2 = filterGeoJSON(this.points, 1);
                this.layer.scatter1.setSource(source1);
                this.layer.scatter2.setSource(source2);
                this.layer.scatter1.setStyle({
                    size: this.centerPointSize,
                    unit: 'miter',
                    animate: true,
                    duration: 1000,
                    texture: 'https://a.amap.com/Loca/static/static/green.png',
                });
                this.layer.scatter2.setStyle({
                    size: this.centerPointSize,
                    unit: 'miter',
                    animate: true,
                    duration: 1000,
                    texture: 'https://a.amap.com/Loca/static/static/orange.png',
                });
                this.loca.add(this.layer.scatter1);
                this.loca.add(this.layer.scatter2);
                this.loca.animate.start();
            // });

        // fetch('https://a.amap.com/Loca/static/static/diplomacy-line.json')
        //     .then((res) => res.json())
        //     .then((data) => {
                // this.lineGeoMapData = Object.entries(data).reduce((accu, curr) => {
                //     var [key, geo] = curr;
                //     accu[key] = new this.Loca.GeoJSONSource({
                //         data: geo,
                //     });
                //     return accu;
                // }, {});
                this.lineGeoMapData = new this.Loca.GeoJSONSource({
                    data: this.lines,
                });
                this.layer.link.setSource(this.lineGeoMapData);
                
                if(this.isPulseLink) {// 弧线
                  this.layer.link.setStyle({
                    unit: 'meter',
                    dash: [40000, 0, 40000, 0],
                    lineWidth: function () {
                      return [200, 100];
                    },
                    height: function (index, feat) {
                        return feat.distance / 3 + 10;
                    },
                    // altitude: 1000,
                    smoothSteps: 30,
                    speed: function () {
                        return 1000 + Math.random() * 200000;
                    },
                    flowLength: 100000,
                    lineColors: function () {
                        return ['rgb(255,228,105)', 'rgb(255,164,105)', 'rgba(1, 34, 249,1)'];
                    },
                    maxHeightScale: 0.4, // 弧顶位置比例
                    headColor: 'rgba(255, 255, 0, 1)',
                    trailColor: 'rgba(255, 255,0,0)',
                  });
                } else {
                  this.layer.link.setStyle({
                      lineColors: function (index, item) {
                          return item.link.properties.type === 0 ? ['#25CDEA', '#12BFBF'] : ['#FFD87B', '#FF4F00'];
                      },
                      height: function (index, item) {
                          return item.distance / 3;
                      },
                      smoothSteps: function () {
                          return 200;
                      },
                  });
                }
                this.loca.add(this.layer.link);
            // });
      }
    },
  }
</script>

<style lang="scss" scoped>
.Map{
  width: 100%;
  height: 100%;
  &-container{
    width: 100%;
    height: 100%;
  }
}
</style>