<template>
  <div class="Declaration">
    <div class="board-title">业务月增长率</div>
    <div class="Declaration-board">
      <div class="Declaration-board-item">
        <el-statistic
          title="客户单量"
          group-separator=","
          :precision="0"
          :value="board.customer"
          suffix="单"
        ></el-statistic>
      </div>
      <div class="Declaration-board-item">
        <el-statistic
          title="贸易金额"
          group-separator=","
          :precision="2"
          :value="board.sum"
          suffix="(美元/万元)"
        ></el-statistic>
      </div>
    </div>
    <line-chart
      :legend="businessMouthLegend"
      :colors="colors"
      type="line"
      :rotate="false"
      :optionsData="businessMouthOptions"
      axisValueName="美元/万元"
    />
  </div>
</template>

<script>
  import LineChart from '../BarLineChart.vue';
  export default {
    name: 'Declaration',
    components: {
      LineChart,
    },
    data() {
      return {
        board: {
          customer: 0,
          sum: 0,
        },
        colors: [ '#34DBFC', '#01BB7B', '#D3A43E', '#FF3757'],
        businessMouthLegend: {
          totalPrice9610: '9610',
          totalPrice9710: '9710',
          totalPrice9810: '9810',
          totalPrice1210: '1210',
        },
        businessMouthOptions: [],
        tradeModes: ['9610', '9710', '9810', '1210']
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('/data/businessTypeData/listByMonth');
        if(res.code === 200) {
          if(res.data && res.data.length) {
            this.businessMouthOptions = res.data.map(item => ({
              name: item.date,
              ...(
                item.priceList
                ?
                  item.priceList
                  .filter(item => item.currency === '美元')
                  .reduce((prev, cur) => {
                    prev[`totalPrice${cur.tradeMode}`] = (cur.totalPrice / 10000).toFixed(2);
                    return prev;
                  }, {})
                : {}
              )
            }));
            this.board.customer = res.data
              .map(item => {
                if(item.numList) {
                  return item.numList
                  .filter(numItem => this.tradeModes.includes(numItem.tradeMode))
                  .reduce((prev, cur) => {
                    prev += cur.num;
                    return prev;
                  }, 0)
                } else {
                  return 0;
                }
              })
              .reduce((prev, cur) => {
                prev += cur;
                return prev;
              }, 0);
            this.board.sum = (res.data
              .map(item => {
                if(item.priceList) {
                  return item.priceList
                  .filter(priceItem => (this.tradeModes.includes(priceItem.tradeMode) && priceItem.currency === '美元'))
                  .reduce((prev, cur) => {
                    prev += cur.totalPrice;
                    return prev;
                  }, 0)
                } else {
                  return 0;
                }
              })
              .reduce((prev, cur) => {
                prev += cur;
                return prev;
              }, 0)) / 10000;
          }
        } else{
          this.$message.error(res.msg);
        }
        console.log(res);
      }
    },
  }
</script>

<style lang="scss" scoped>
.Declaration{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-board{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    &-item {
      width: 230px;
      background-color: rgba(24, 122, 205, 0.2);
      ::v-deep(.el-statistic){
        text-align: left;
        .head{
          padding: 8px 0;
          margin-bottom: 10px;
          text-align: center;
          background-color: rgba(24, 122, 205, 0.3);
          .title{
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .con{
          padding: 15px 0 20px;
          .number{
            font-size: 24px;
            font-weight: 700;
            font-family: DINAlternate-Bold, DINAlternate;
            color: #fff;
          }
          .suffix{
            font-size: 12px;
            color: #fff;
          }
        }
      }
      &:first-child{
        ::v-deep(.el-statistic){
          .con{
            .number{
              color: #01BB7B;
            }
          }
        }
      }
      &:last-child{
        ::v-deep(.el-statistic){
          .con{
            .number{
              color: #FF9502;
            }
          }
        }
      }
    }
  }
  .BarLineChart{
    flex: 1;
  }
}
</style>