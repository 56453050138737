<template>
  <div class="DataTable">
    <div class="board-title">业务综合数据表-月度</div>
    <div class="DataTable-table">
      <ScrollDataTable
        :columns="columns"
        :tableData="tableData"
      />
      <!-- <el-table
        :data="tableData"
        :highlight-current-row="false">
        <el-table-column
          prop="date"
          label="年月"
          width="100">
        </el-table-column>
        <el-table-column
          prop="totalPrice9610"
          label="9610贸易额"
          width="120">
        </el-table-column>
        <el-table-column
          prop="DECLARATION_MODE9610"
          label="9610报关单"
          width="100">
        </el-table-column>
        <el-table-column
          prop="totalPrice9710"
          label="9710贸易额"
          width="120">
        </el-table-column>
        <el-table-column
          prop="DECLARATION_MODE9710"
          label="9710报关单"
          width="100">
        </el-table-column>
        <el-table-column
          prop="totalPrice9810"
          label="9810贸易额"
          width="120">
        </el-table-column>
        <el-table-column
          prop="DECLARATION_MODE9810"
          label="9810报关单"
          width="100">
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
  import ScrollDataTable from '../DataTable.vue';
  import { mapState } from 'vuex';
  export default {
    name: 'DataTable',
    components: {
      ScrollDataTable,
    },
    data() {
      return {
        columns: [
          { label: '年月', prop: 'date' },
          { label: '9610（万元）', prop: 'totalPrice9610', key: 'price', tradeMode: '9610' },
          { label: '9610（单）', prop: 'num9610' },
          { label: '9710（万元）', prop: 'totalPrice9710', key: 'price', tradeMode: '9710' },
          { label: '9710（单）', prop: 'num9710' },
          { label: '9810（万元）', prop: 'totalPrice9810', key: 'price', tradeMode: '9810' },
          { label: '9810（单）', prop: 'num9810'},
        ],
        tableData: []
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('/data/businessTypeData/listByMonth');
        if(res.code === 200) {
          if(res.data && res.data.length) {
            this.tableData = res.data.map((item, index) => ({
              id: index,
              date: item.date,
              ...(
                item.numList
                ?
                  item.numList.reduce((prev, cur) => {
                    prev[`num${cur.tradeMode}`] = cur.num;
                    return prev;
                  }, {})
                : {}
              ),
              ...(
                item.priceList
                ?
                  item.priceList.filter(item => ["美元", '日本元'].includes(item.currency)).reduce((prev, cur) => {
                    const { currency } = cur;
                    let symbol = '';
                    switch(currency) {
                      case '美元' :
                        symbol = 'USD';
                        break;
                      case '日本元' :
                        symbol = 'JPY';
                        break;
                    }
                    prev[`totalPrice${cur.tradeMode}`] = `${(cur.totalPrice / 10000).toFixed(3)}`;
                    prev[`currency${cur.tradeMode}`] = symbol;
                    return prev;
                  }, {})
                : {}
              )
            }));
          }
        } else{
          this.$message.error(res.msg);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.DataTable{
  display: flex;
  flex-direction: column;
  &-table{
    flex: 1;
    padding: 5px 20px 20px;
    ::v-deep(.el-table){
      background-color: transparent;
      tr{
        background-color: transparent;
        th.el-table__cell{
          background-color: transparent;
          border-bottom: 1px solid rgba(24, 122, 205, 0.88);
          .cell{
            color: rgba(25, 204, 219, 1);
          }
        }
      }
      .el-table__row{
        .el-table__cell{
          .cell{
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background-color: transparent;
      }
      td.el-table__cell,
      th.el-table__cell.is-leaf{
        border-bottom: none;
      }
      .el-table__empty-text{
        color: #fff;
      }
      &::before{
        background-color: transparent;
      }
    }
  }
}
</style>