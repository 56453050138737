<template>
  <div class="Declaration">
    <div class="board-title">业务申报</div>
    <bar-chart
      :legend="businessLegend"
      :colors="colors"
      :barWidth="10"
      :optionsData="businessOptions"
      axisValueName="单"
    />
  </div>
</template>

<script>
  import BarChart from '../BarLineChart.vue';
  export default {
    name: 'Declaration',
    components: {
      BarChart,
    },
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        colors: [ '#01BB7B', '#FF9502', '#34DBFC'],
        businessLegend: {
          export9610: '9610出口',
          export9710: '9710出口',
          export9810: '9810出口'
        },
        businessOptions: [],
      }
    },
    mounted () {
      this.getDeclareData();
    },
    methods: {
      async getDeclareData() {
        const res = await this.$request.get('/data/portBusinessData/ratio', { params: this.queryDate});
        if(res.data && res.data.length) {
          res.data.forEach(item => {
            const { tradeMode, portName, num } = item;
            const isHasIndex = this.businessOptions.findIndex(item => item.name === portName);
            if(isHasIndex === -1) {
              this.businessOptions.push({
                name: portName,
                [`export${tradeMode}`]: num,
              });
            } else {
              this.businessOptions[isHasIndex][`export${tradeMode}`] = num;
            }
          });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.Declaration{
  width: 100%;
  height: 100%;
  .board-title{
    background-position: 40% 47%;
  }
}
</style>