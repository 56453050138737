<template>
  <DataScreenBox>
    <div class="LargeScreenSynthesis">
      <Header title="海丰电商-数据看板" :curDate="curDate" />
      <div class="main">
        <!-- <div class="map-container">
          <Map />
        </div>
        <div class="main-container"> -->
          <div class="main-left">
            <div class="main-left-top">
              <ExportingCountryTrade
                :queryDate="queryDate"
              />
            </div>
            <div class="main-left-bottom">
              <Customers
                :queryDate="queryDate"
              />
            </div>
          </div>
          <div class="main-center">
            <div class="main-center-top">
              <Overview :queryDate="queryDate" />
              <Map :points="pointsJson" :lines="lineJson" />
            </div>
            <div class="main-center-bottom">
              <DataTable />
            </div>
          </div>
          <div class="main-right">
            <div class="main-right-top">
              <Declaration
                :queryDate="queryDate"
              />
            </div>
            <div class="main-right-bottom">
              <Business />
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </DataScreenBox>
</template>

<script>
import * as dayjs from 'dayjs';

import DataScreenBox from '@/components/DataScreenBox.vue';

import Header from '../Header.vue';
import Business from './Business.vue';
import Customers from './Customers.vue';
import DataTable from './DataTable.vue';
import Declaration from './Declaration.vue';
import ExportingCountryTrade from './ExportingCountryTrade.vue';
import lineJson from './line.json';
import Map from './Map.vue';
import Overview from './Overview.vue';
import pointsJson from './points.json';

export default {
    name: 'LargeScreenSynthesis',
    components: {
      DataScreenBox,
      Header,
      ExportingCountryTrade,
      Customers,
      Overview,
      Map,
      DataTable,
      Declaration,
      Business,
    },
    data() {
      return {
        curDate: dayjs().format("YYYY-MM-DD"),
        queryDate: {
          startDate: dayjs('2020-01-01').startOf().format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().endOf().format("YYYY-MM-DD 23:59:59"),
        },
        pointsJson,
        lineJson
      }
    },
  }
</script>

<style lang="scss" scoped>
.LargeScreenSynthesis{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main{
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;
    // position: relative;
    // width: 100%;
    // height: 100%;
    // .map-container{
    //   width: 100%;
    //   height: 100%;
    // }
    // .main-container{
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   flex: 1;
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-left{
      width: 528px;
      &-top{
        height: 592px;
        background: url('~@/assets/images/dataScreen/bg_left_1.svg') center center no-repeat;
      }
      &-bottom{
        height: 334px;
        margin-top: 30px;
        background: url('~@/assets/images/dataScreen/bg_left_2.svg') center center no-repeat;
      }
    }
    &-center{
      width: 800px;
      &-top{
        height: 592px;
        background: url('~@/assets/images/dataScreen/bg_center_1.svg') center center no-repeat;
        display: flex;
        flex-direction: column;
        .Map-container{
          flex: 1;
        }
      }
      &-bottom{
        height: 334px;
        margin-top: 30px;
        background: url('~@/assets/images/dataScreen/bg_center_2.svg') center center no-repeat;
      }
    }
    &-right{
      width: 528px;
      &-top{
        height: 442px;
        background: url('~@/assets/images/dataScreen/bg_right_1.svg') center center no-repeat;
      }
      &-bottom{
        height: 502px;
        margin-top: 15px;
        background: url('~@/assets/images/dataScreen/bg_right_2.svg') center center no-repeat;
      }
      
    }
  }
}
</style>