<template>
  <div class="Customers">
    <div class="board-title">9610/9710客户统计</div>
    <bar-chart
      :legend="customerLegend"
      :colors="colors"
      :optionsData="customerOptions"
      axisValueName="单"
      selectedMode="single"
    />
  </div>
</template>

<script>
  import BarChart from '../BarLineChart.vue';
  export default {
    name: 'Customers',
    components: {
      BarChart,
    },
    props: {
      queryDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        colors: [
          [ '#60F5FF', '#3FA4FF' ],
          [ '#FF72A6', '#FF3657' ],
        ],
        customerLegend: { 
          type9610: '9610', 
          type9710: '9710'
        },
        customerOptions: [],
      }
    },
    mounted () {
      this.getDeclareData();
    },
    methods: {
      async getDeclareData() {
        let allData = [];
        const res9610 = await this.$request.get('/data/customerBusinessData/overallQuery', {
          params: {
            ...this.queryDate,
            tradeMode: '9610'
          }
        });
        if(res9610.data && res9610.data.content.length) {
          allData = [ ...allData, ...res9610.data.content ];
        }
        const res9710 = await this.$request.get('/data/customerBusinessData/overallQuery', {
          params: {
            ...this.queryDate,
            tradeMode: '9710'
          }
        });
        if(res9710.data && res9710.data.content.length) {
          allData = [ ...allData, ...res9710.data.content ];
          allData.forEach(item => {
            const { tradeMode, customerName, num, priceList } = item;
            const priceUSD = priceList.find(item => (item.currencyName === '美元'))?.totalPrice;
            const priceJPY = priceList.find(item => (item.currencyName === '日本元'))?.totalPrice;
            const isHasIndex = this.customerOptions.findIndex(item => item.name === customerName);
            if(isHasIndex === -1) {
              this.customerOptions.push({
                name: customerName,
                [`type${tradeMode}`]: num,
                tradeMode,
                priceUSD: priceUSD || '---',
                priceJPY: priceJPY || '---',
              });
            } else {
              this.customerOptions[isHasIndex][`type${tradeMode}`] = num;
            }
          });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.Customers{
  width: 100%;
  height: 100%;
  .board-title{
    background-position: 31% 47%;
  }
}
</style>